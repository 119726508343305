import { createSlice } from '@reduxjs/toolkit';
import { logoutAllTabs } from "./../../utils/logout";
const token = localStorage.getItem('token'); //jwt token
// detect langauge
let set_language;
let language = window.location.pathname.split('/')[1]; //en || ar
if ('en' === language || 'ar' === language) {
	localStorage.setItem('language', language);
	set_language = language;
} else {
	let localLang = localStorage.getItem('language');
	set_language = localLang === 'en' ? localLang : 'ar';
	// set_language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
	localStorage.setItem('language', set_language);
}

// create the initial state
const initialState = { user_info: [], isLoggedIn: token ? true : false, language: set_language };
// Slice
const userSlice = createSlice({
	name: 'user', // name of the slice
	initialState, // initial state
	reducers: {
		LOGIN: (state = initialState, action) => {
			state.user_info = action?.payload?.user_info;
			state.isLoggedIn = true;
            state.language = set_language;
			localStorage.setItem('token', action?.payload?.token);
		},
		LOGOUT: (state) => {
			logoutAllTabs();
			state.user_info = [];
			state.isLoggedIn = false;
            state.language = set_language;
            localStorage.removeItem("token");
		},
        SET_LANGUAGE: (state, action) => {
            state.language = action.payload;
        },
		UPDATE_USER_INFO: (state, action) => {
			//update user single info
			state.user_info.first_name = action?.payload?.first_name;
			state.user_info.last_name = action?.payload?.last_name;
			state.user_info.email = action?.payload?.email;
			state.user_info.phone_no = action?.payload?.phone_no;
			state.user_info.user_type = action?.payload?.user_type;
			state.user_info.profile_image = action?.payload?.profile_image;
		},
		UPDATE_COMPANY: (state, action) => {
			state.user_info.companies = action.payload;
		},

	},
});

export const { LOGIN, LOGOUT, SET_LANGUAGE, UPDATE_USER_INFO, UPDATE_COMPANY } = userSlice.actions; // action creators
export const loggedinUserInfo = (state) => state.user.user_info; //get all user data from store
export default userSlice.reducer; //reducer
