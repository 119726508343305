import { BroadcastChannel } from 'broadcast-channel';
const logoutChannel = new BroadcastChannel('logout');
const loginChannel = new BroadcastChannel('login');

export const logout = () => {
    logoutChannel.postMessage("Logout");  
    localStorage.removeItem("token");
    let language = localStorage.getItem('language');    
    window.location.href = window.location.origin +`/${language}/sign-out`;
}

export const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
        logout();
        logoutChannel.close();   
    }
}

export const login = () => {
    loginChannel.postMessage("LogIn"); 
    let language = localStorage.getItem('language');
    let rootState = JSON.parse(localStorage.getItem('rootState'));   
    if( 'C' === rootState?.user?.user_info?.user_type ){	
        window.location.href = window.location.origin +`/${language}/subscriptions`;	
    }else  if( 'A' === rootState?.user?.user_info?.user_type || 'S' === rootState?.user?.user_info?.user_type ){
        window.location.href = window.location.origin +`/${language}/admin/dashboard`;   
    }else{
        window.location.href =  window.location.origin + "/";
    }
}
export const loginAllTabs = () => {
    loginChannel.onmessage = () => {
        login();
        loginChannel.close();   
    }
}
