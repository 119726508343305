
import moment from 'moment';
const checkTokenValidate = (err) => {
    if (401 == err?.response?.status) {
      localStorage.removeItem('token');
      localStorage.removeItem('rootState');
      window.location.reload();
    }
  };
const isEmpty = (val) => {
  return val === undefined || val == null || val.length <= 0 ? true : false;
};
const ProfileImageType = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
];
const ProfileImageSize = 1050000; //1050000;
const SubscriptionFileType = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
const SubscriptionFileSize = 1050000; //1050000;
const  DESIGNATIONLIST = [
  { value: '1', en: 'Auditing', ar: 'تدقيق' },
  { value: '2', en: 'Tax', ar:'ضريبة' },  
  { value: '3', en: 'Finance', ar: 'المالية' },  
];
const defaultCompanyServiceLink = ({users, services},language) => {
  if( 'C' === users?.user_type ){	
    return `/${language}/subscriptions`;	
  }else{
    return `/${language}/admin/dashboard`;
  }
}
//Files Upload validation constant
const UploadFileTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
  'image/tif',
  'image/tiff',
  'application/pdf', //for pdf
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //for docx
  'application/msword', //for doc
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //for xlsx
  'application/vnd.ms-excel', //for xls
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  //for xlsm, xlam
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-outlook',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
];
const UploadFileSize = 15500000; //bytes-15 mb

const showDateOrTimeFormat = (date, type) => {
  if(type=='date'){
    return moment(date).format('Do MMMM YYYY');
  }
  if(type=='time'){
    return moment(date).format('hh:mm A');
  } 
  return moment(date).format('MMMM Do YYYY hh:mm A'); 
}

const dateTimeAgo = (date,language='ar') => {
  moment.updateLocale('ar',{
    relativeTime : {
      future: "in %s",
      past:   "%s",
      s:  "منذ ثوانى",
      m:  "قبل دقيقة",
      mm: "%d دقائق مضت",
      h:  "قبل ساعة",
      hh: "%d ح قبل",
      d:  "قبل يوم",
      dd: "%d قبل د",
      M:  "قبل شهر",
      MM: "%d منذ أشهر",
      y:  "قبل عام",
      yy: "%d مند سنوات"
    }
});
  moment.locale(language);
  return moment(date).fromNow()
}
const deepCopyArray = (array) => {
  if(array?.length){
    return JSON.parse(JSON.stringify(array))
  }else{
    return new Array()
  }
}
const subscriptionPaymentStatus = (status) =>{
  let full_status;
  switch(status){
    case 'NP':
      full_status = 'Not Paid';
      break;
    case 'PN':
      full_status = 'Under Process';
      break;
    case 'PD':
      full_status = 'Active';
      break;
    case 'PR':
      full_status = 'Reject';
      break;
    case 'EX':
      full_status = 'Expired';
      break;
    default:
      full_status = 'ALL';
  }
  return full_status;
}
export {
	checkTokenValidate,
  isEmpty,
  ProfileImageType,
  ProfileImageSize,
  DESIGNATIONLIST,
  defaultCompanyServiceLink,
  UploadFileTypes,
  UploadFileSize,
  showDateOrTimeFormat,
  SubscriptionFileType,
  SubscriptionFileSize,
  deepCopyArray,
  subscriptionPaymentStatus,
  dateTimeAgo
};


