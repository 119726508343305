import { createSlice } from '@reduxjs/toolkit';

// create the initial state
const initialState = { breadcum_info:[], move_folder_breadcum:[]};

// Slice
const commonSlice = createSlice({
	name: 'common', // name of the slice
	initialState, // initial state
	reducers: {
		SETBREADCUM: (state = initialState, action) => {
			state.breadcum_info = action?.payload;
		},
		SET_MOVE_FOLDER_BREADCUM: (state = initialState, action) => {
			state.move_folder_breadcum = action?.payload;
		}

	},
});

export const { SETBREADCUM,SET_MOVE_FOLDER_BREADCUM } = commonSlice.actions; // action creators
export const commonInfo = (state) => state.common; //get all user data from store
export default commonSlice.reducer; //reducer