import { combineReducers } from 'redux';
// import { withReduxStateSync } from 'redux-state-sync';
import userReducer from '../slices/UserSlice';
import serviceReducer from '../slices/ServiceSlice';
import commonReducer from '../slices/CommonSlice';

//set all the reducers
const combinedReducer = combineReducers({
	user: userReducer,
	services: serviceReducer,
	common:commonReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'user/LOGOUT') {
		// clear everything but keep the stuff we want to be preserved ..
		state.services = {}; //clear the services state after logout
	}
	return combinedReducer(state, action);
};

export default rootReducer;


