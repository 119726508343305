import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
//for redux
import { Provider } from 'react-redux';
import store from './store/store';
import {useSelector} from 'react-redux';
import { initGA, logPageView } from './utils/analytics';

//for English Arabic bootstrap css load
const EnCSS = lazy(() => import('./assets/changeCSS/bootstrapEnCSS'));
const ArCSS = lazy(() => import('./assets/changeCSS/bootstrapArCSS'));
const root = ReactDOM.createRoot(document.getElementById('root'));

//load css English and Arabic based on language
const CssSelector = ({ children }) => {
	//add class
	const language = useSelector((state) => state.user.language);
	let classname = language === 'en' ? 'en' : 'ar';
	document.body.classList.remove('en', 'ar');
	document.body.classList.add(classname);
	document.documentElement.setAttribute('lang', language);

	return (
		<>
			<Suspense fallback={<></>}>
				{language === 'en' && <EnCSS />}
				{language === 'ar' && <ArCSS />}
			</Suspense>
			{children}
		</>
	);
};
initGA(); //for google analytics initialize
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<CssSelector>
				<App />
			</CssSelector>
		</Provider>
	</BrowserRouter>
);
logPageView(); //for google analytics page view
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
