import { createSlice } from '@reduxjs/toolkit';

// create the initial state
const initialState = { service_info: [] };

// Slice
const serviceSlice = createSlice({
	name: 'service_slice', // name of the slice
	initialState, // initial state
	reducers: {
		SERVICES: (state = initialState, action) => {
			state.service_info = action?.payload;
		},
	},
});

export const { SERVICES} = serviceSlice.actions; // action creators
export const serviceInfo = (state) => state.services?.service_info; //get all user data from store
export default serviceSlice.reducer; //reducer