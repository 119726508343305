import React, {useLayoutEffect, useEffect, Suspense} from 'react';
import {useLocation} from 'react-router-dom';
import { lazy } from '@loadable/component';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.rtl.min.css';
// import './assets/css/fontawesome.css';
// import './assets/scss/style.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import  Loading  from './components/shared_components/Loading';
// import ReactGA from 'react-ga';
/** Public **/
const Home = lazy(() => import('./components/pages/Home'));
const AboutUs = lazy(() => import('./components/pages/AboutUs'));
const Service = lazy(() => import('./components/pages/Service'));
const Career = lazy(() => import('./components/pages/Career'));
const Pricing = lazy(() => import('./components/pages/Pricing'));
const ContactUs = lazy(() => import('./components/pages/ContactUs'));
const SignIn = lazy(() => import('./components/pages/SignIn'));
const SignUp = lazy(() => import('./components/pages/SignUp'));
const SignOut = lazy(() => import('./components/pages/SignOut'));
const ForgotPassword = lazy(() => import('./components/pages/ForgotPassword'));
const EmailVerification = lazy(() => import('./components/pages/EmailVerification'));
const SetPassword = lazy(() => import('./components/pages/SetPassword'));
/** Client **/
const ClientOutlet = lazy(() => import('./components/shared_components/ClientOutlet'));
/** Admin **/
const AdminOutlet = lazy(() => import('./components/shared_components/AdminOutlet'));

//for redux
import { useSelector,useDispatch } from 'react-redux';
import { SET_LANGUAGE } from './store/slices/UserSlice';
// import { useDispatch, useSelector } from 'react-redux';

// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID); // process.env.GOOGLE_ANALYTICS_ID
import { logoutAllTabs , loginAllTabs} from "./utils/logout";


function App() {
  const dispatch = useDispatch();
  
  // Boardcast chanel call
    useEffect(() => {
     logoutAllTabs();     
     loginAllTabs();
    }, []);
    
  useLayoutEffect(() => {
   //if user put langauge in url and enter
    let language = localStorage.getItem('language'); //en || ar
    if(language){
      dispatch(SET_LANGUAGE(language)); //redux
    }    
		function updateSize() {
			let mhheight = document.querySelector('.mainheader')?.clientHeight;
			let mfheight = document.querySelector('.mainfooter')?.clientHeight;
			let ttlheight = mhheight + mfheight + 1;
			let mbheight = window.innerHeight - ttlheight;
			if (mbheight) {
				document.querySelector('.mainbody').style.minHeight = mbheight + 'px';
			}
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
  
  function useScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  }
  useScrollToTop();
  const language = useSelector((state) => state.user.language);
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <Suspense fallback={<Loading widthSize={'100%'} />}>
     <Routes>
        <Route path={`/:language`} name="Home" element={<Home/>}/>
        <Route path={`/:language/about-us`} name="About us" element={<AboutUs/>}/>
        <Route path={`/:language/services/auditing`} name="Auditing service" element={<Service/>}/>
        <Route path={`/:language/services/zakat-tax`} name="Zakat and tax service" element={<Service/>}/>
        <Route path={`/:language/services/accounting`} name="Accounting service" element={<Service/>}/>
        <Route path={`/:language/services/local-content-auditing`} name="Local content auditing" element={<Service/>}/>
        <Route path={`/:language/career`} name="Career" element={<Career/>}/>
        <Route path={`/:language/pricing`} name="Pricing" element={<Pricing/>}/>
        <Route path={`/:language/contact-us`} name="Contact us" element={<ContactUs/>}/>
        <Route path={`/:language/sign-in`} name="Sign in" element={<SignIn/>}/>
        <Route path={`/:language/sign-up`} name="Sign up" element={<SignUp/>}/>
        <Route path={`/:language/sign-out`} name="Sign out" element={<SignOut/>}/>
        <Route path={`/:language/forgot-password`} name="Forgot password" element={<ForgotPassword/>}/>
        <Route path={`/:language/verify-email/:token`} name="Email verification" element={<EmailVerification />} />
        <Route path={`/:language/set-password/:token`} name="Set Password" element={<SetPassword/>} />
        <Route path="*" element={<Navigate to={`/${language}/`} />} />
        <Route path={`/:language/*`} element={<ClientOutlet />} />        
        <Route path={`/:language/admin/*`} element={<AdminOutlet />} />      
      </Routes>
    </Suspense>
  );
}
export default App;