import React from 'react';
import PropTypes from 'prop-types';
const Loading = ({editPage=false, loadClass='', widthSize='', showMessage=null}) => {
    return (
		<>
			{!editPage ? (
				<div
					className={`fullloading ${loadClass}`}
					style={{ textAlign: 'center', paddingTop: '10px', width: widthSize ? widthSize : 'calc(100% - 230px)' }}
				>
					<div className="load-circle1"></div>
					<div className="load-circle2"></div>
					<div className="load-circle3"></div>
					<div className="load-circle4"></div>
					<div className="load-circle5"></div>
					{showMessage && <p className='txt-gren'>{showMessage}</p>}
				</div>
			) : (
				<div className="fetcheditdata">
					<span>
						<div className="load-circle1"></div>
						<div className="load-circle2"></div>
						<div className="load-circle3"></div>
						<div className="load-circle4"></div>
						<div className="load-circle5"></div>
					</span>
				</div>
			)}
		</>
	);
};
/**** we should used prop-types for validate passing prototype  */
Loading.propTypes = {
    editPage: PropTypes.bool,
	loadClass: PropTypes.string,
}
export default Loading;