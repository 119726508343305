import { configureStore } from '@reduxjs/toolkit';

// import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
const middleware = [thunk];
const {isEmpty} = require('../utils/helper');//Util import

//TODO: Next we can implement Redux Persist for storage in local storage

//set the state to local storage when the state changes
const localStorageMiddleware = ({ getState }) => {
	return (next) => (action) => {
		const result = next(action);
		localStorage.setItem('rootState', JSON.stringify(getState()));
		return result;
	};
};

//after refresh get the state from local storage and merge it with the initial state
const reHydrateStore = () => {
	if (!isEmpty(localStorage.getItem('rootState'))) {		 // chnage by asif if manually remove rootState
		return JSON.parse(localStorage.getItem('rootState')); // re-hydrate the store
	}
};

//combine all reducers and add the middleware to the store
const store = configureStore({
    reducer: rootReducer, //withReduxStateSync
	preloadedState: reHydrateStore(), // re-hydrate the store
    middleware: [...middleware, localStorageMiddleware], //add the middleware for local storage
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
	// compose: composeWithDevTools(), // add the devTools
});

export default store;
